$(document).ready(function () {

    const url = new URL(window.location.href);
    const page = url.searchParams.get('page');
    const customerIdAlt = url.searchParams.get('customerId');
    const source = url.searchParams.get('source');
    const purchaseId = url.searchParams.get('purchaseId');
    const message = url.searchParams.get('message');
    const messageClass = url.searchParams.get('messageClass');

    function getCurrentDate() {
        let d = new Date();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        let cur = d.getFullYear() + '/' +
            (month < 10 ? '0' : '') + month + '/' +
            (day < 10 ? '0' : '') + day;
        return cur;
    }

    function invertDate(date) {
        let invertedDate = date.replace(/-/g, "/");
        let YMD = invertedDate.split("/");
        let rightDate = "";
        for (let i = YMD.length - 1; i >= 0; i--) {
            rightDate += YMD[i];
            rightDate += (i == 0) ? "" : "/";
        }
        return rightDate;
    }

    function checkBirth(inYear, inMonth, inDay) {
        let msg = '';
        let month = inMonth.replace(/\s/g, '');
        let year = inYear.replace(/\s/g, '');
        let day = inDay.replace(/\s/g, '');
        let date = new Date(year, (month - 1), day);

        if (!/^\d+$/.test(month) || !/^\d+$/.test(year) || !/^\d+$/.test(day)) {
            msg += 'I campi data di nascita devono essere numerici';
        } else {
            let dateTime = new Date();

            month = parseInt(month);
            year = parseInt(year);
            day = parseInt(day);

            // if day of month is wrong
            if (
                (isLeapYear(year) && (month == 2) && (day < 1 || day > 29)) || // february in leap year case
                (!isLeapYear(year) && (month == 2) && (day < 1 || day > 28)) || // february not in leap year case
                ((month == 1 || month == 3 || month == 5 || month == 7 || month == 8 || month == 10 || month == 12) && (day < 1 || day > 31)) ||  // 31 days months cases
                ((month == 4 || month == 6 || month == 9 || month == 11) && (day < 1 || day > 30)) // 30 days months cases
            ) {
                msg += 'Giorno di nascita errato.\n';
            }
            if (month < 1 || month > 12) {
                msg += 'Mese di nascita errato.\n';
            }
            if (year < 1850 || year > dateTime.getFullYear()) {
                msg += 'Anno di nascita errato.\n';
            }
        }
        if (msg === '') {
            return true;
        } else {
            alert(msg);
            return false;
        }
    }

    function isLeapYear(year) {
        return ((year % 4 == 0) && (year % 100 != 0)) || (year % 400 == 0);
    }

    // SIGNATURE PAD

    if ($('canvas').length) {
        var canvas = document.querySelector("canvas");
        var signaturePad = new SignaturePad(canvas);

        $("button[data-action=clear]").click(function () {
            signaturePad.clear();
        });

        function resizeCanvas() {
            var ratio = Math.max(window.devicePixelRatio || 1, 1);
            canvas.width = canvas.offsetWidth * ratio;
            canvas.height = canvas.offsetHeight * ratio;
            canvas.getContext("2d").scale(ratio, ratio);
            signaturePad.clear(); // otherwise isEmpty() might return incorrect value
        }

        window.addEventListener("resize", resizeCanvas);
        resizeCanvas();
        $('.privacy-popup').css('opacity', '1').hide();
    }
    // END - SIGNATURE PAD

    // AUTORESIZE TEXT-AREA
    var autoExpand = function (field) {
        // Reset field height
        field.style.height = 'calc(1.6em + 0.75rem + 2px)';

        // Get the computed styles for the element
        var computed = window.getComputedStyle(field);

        // Calculate the height
        var height = parseInt(computed.getPropertyValue('border-top-width'))
            + parseInt(computed.getPropertyValue('padding-top'))
            + field.scrollHeight
            + parseInt(computed.getPropertyValue('padding-bottom'))
            + parseInt(computed.getPropertyValue('border-bottom-width'));
        field.style.height = height + 'px';
    };

    document.addEventListener('input', function (event) {
        if (event.target.tagName.toLowerCase() !== 'textarea') return;
        autoExpand(event.target);
    }, false);

    // END - AUTORESIZE TEXT-AREA

    $('aside.messages i.fa-times').click(function () {
        $('aside.messages').hide(800);
    });

    if (message != null) {
        $('aside.messages div.message').html(message);
        $('aside.messages')
            .attr('class', 'messages')
            .addClass(messageClass)
            .fadeIn(800);
        setTimeout(function () {
            $('aside.messages').fadeOut(800);
        }, 5000);
    }

    $.ajaxSetup({
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        contentType: 'application/json;charset=utf-8',
        cache: false
    });

    $.fn.serializeFormJSON = function () {
        var obj = {};
        var arr = this.serializeArray();
        $.each(arr, function () {
            if (obj[this.name]) {
                if (!obj[this.name].push) {
                    obj[this.name] = [obj[this.name]];
                }
                obj[this.name].push(this.value || '');
            } else {
                obj[this.name] = this.value || '';
            }
        });
        return obj;
    };

    function displayErrors(result) {
        $('.is-danger').hide();
        if (result.responseJSON.errors) {
            $.each(result.responseJSON.errors, function (field, errorText) {
                $('.error-' + field).show();
            });
        }
    };

    function paddingForStickyHeader() {
        $('body:not(.welcome) main').css('padding-top', $('nav.main-navbar').outerHeight());
        $('nav.main-navbar').addClass('fixed');
    };

    $(".datepicker").datepicker({
        showOn: "button",
        buttonImage: "/images/calendar.svg",
        buttonImageOnly: true,
        buttonText: "Select date",
        maxDate: '+0m +0w'
    });

    // STICKY HEADER //
    paddingForStickyHeader();

    $(window).resize(function () {
        paddingForStickyHeader();
    });

    // END - STICKY HEADER //

    function last(array, n) {
        if (array == null)
            return void 0;
        if (n == null)
            return array[array.length - 1];
        return array.slice(Math.max(array.length - n, 0));
    };

    $(document).on('click', '.show-privacy-popup', function () {
        let emptyFields = [];
        $('.form-control').each(function () {
            if ($(this).val() == '' && (
                $(this).attr('name') == 'name' ||
                $(this).attr('name') == 'surname') // ||
                // $(this).attr('name') == 'email') ||
                // $(this).attr('name') == 'birth_day' ||
                // $(this).attr('name') == 'birth_month' ||
                // $(this).attr('name') == 'birth_year')
            ) {
                emptyFields.push($(this));
            }
        });
        // set for prevalidation birthdate
        let year = $('select[name=birth_year]').val();
        let month = $('select[name=birth_month]').val();
        let day = $('select[name=birth_day]').val();

        //prevalidation new-customer
        if ($('body').hasClass('customer-add') && emptyFields.length > 0) {
            let msg = (emptyFields.length == 1) ? 'Il seguente campo non può essere vuoto: ' : 'I campi seguenti non possono essere vuoti: ';
            emptyFields.forEach(function (item) {
                switch (item.attr('name')) {
                    case 'name':
                        msg += 'nome';
                        break;
                    case 'surname':
                        msg += 'cognome';
                        break;
                    // case 'email':       msg += 'email';     break;
                    case 'birth_day':
                        msg += 'giorno';
                        break;
                    case 'birth_month':
                        msg += 'mese';
                        break;
                    case 'birth_year':
                        msg += 'anno';
                        break;
                }
                msg += (item.attr('name') != last(emptyFields).attr('name')) ? ', ' : '.';
            });
            alert(msg);
            emptyFields.forEach(function (item) {
                item.css('border-color', 'red')
                    .addClass('shake');
                setTimeout(function () {
                    $(".shake").removeClass('shake');
                }, 500);
            });
        } else {
            $('.privacy-popup').fadeIn();
            $('.privacy-popup span[name=name]').html($('input[name=name]').val());
            $('.privacy-popup span[name=surname]').html($('input[name=surname]').val());
        }
    });

    $(document).on('click', '.privacy-popup i.fa-times', function () {
        $('.privacy-popup').fadeOut();
    });

    $(document).on('click', '.resultHead', function () {
        if ($(this).hasClass('active')) {
            $(this).removeClass('active')
                .next('.resultBody')
                .slideUp();
        } else {
            $('.resultHead').removeClass('active');
            $('.resultBody').slideUp();

            let currentEl = $(this).closest('.resultBlock');
            $(this).addClass('active').next('.resultBody').slideDown(400, 'swing', function () {
                $('html, body').animate({
                    scrollTop: currentEl.offset().top - $('nav.main-navbar').outerHeight()
                }, 500);
            });

            // customer update for history search
            let customerId = $(this).parent('.resultBlock').data('id');
            $.get(baseUrl + '/customer/updateforhistorysearch/' + customerId);
        }
    });

    if (customerIdAlt !== null) {
        $('#resultBlock-' + customerIdAlt + ' .resultHead').addClass('active');
        $('#resultBlock-' + customerIdAlt + ' .resultBody').slideDown();

        let destinationModifier = source == 'purchase' ? ' .order-row[data-purchase-id=' + purchaseId + ']'
            : source == 'newPurchase' ? ' .order-row:last-child'
                : '';
        $('html, body').animate({
            scrollTop: $('#resultBlock-' + customerIdAlt + destinationModifier)
                .offset().top - $('nav.main-navbar').outerHeight()
        }, 500);
    }

    function scrollFormCustomer(customerId) {
        $('html, body').animate({
            scrollTop: $('#resultBlock-' + customerId).offset().top - $('nav.main-navbar').outerHeight()
        }, 500, 'swing', function () {
            $('#resultBlock-' + customerId + ' .resultHead').addClass('active');
            $('#resultBlock-' + customerId + ' .resultBody').slideDown();
        });
        // $('#resultBlock-' + customerId + ' .resultHead').trigger('click');
    }

    // DISABILITATO SUBMIT FORM
    $('form[name="form-search"]').submit(function (event) {
        event.preventDefault();
        return false;
    });
    // END - DISABILITATO SUBMIT FORM

    // AJAX CALLS

    // Returns a function, that, as long as it continues to be invoked, will not
    // be triggered. The function will be called after it stops being called for
    // N milliseconds. If `immediate` is passed, trigger the function on the
    // leading edge, instead of the trailing.
    function debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this, args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    };

    // This will apply the debounce effect on the keyup event
    // And it only fires 500ms or half a second after the user stopped typing
    $('.keyword').on('keyup', debounce(function () {
        let keyword = $('.keyword').val();
        if (keyword == null || keyword == false || keyword.length <= 2) {
            $('#resultsList').empty();
        }
        if (keyword.length > 3) {
            let dataForm = JSON.stringify($('form[name="form-search"]').serializeFormJSON());

            $.post(baseUrl + '/customer/autocomplete', dataForm, function (output) {
                $('#resultsList').empty();

                if (output.length === 0) {
                    $('#resultsList')
                        .append('<li class="resultBlock p-2 text-center">Nessun utente per la ricerca ' + keyword + '</li>');
                } else {
                    $('#resultsList').append(output);
                    $(".datepicker").datepicker({
                        showOn: "button",
                        buttonImage: "/images/calendar.svg",
                        buttonImageOnly: true,
                        buttonText: "Select date",
                        maxDate: '+0m +0w'
                    });
                }
            });
        }//fine codice chiamata al BE

    }, 600));

    if ($.trim($('.keyword').val()) != '') {
        $('.keyword').trigger('keyup');
    }

    $(document).on('click', '.button-save-customer', function (event) {
        //If is not chacked privacy-policy-acceptance
        if (!$("input[name=privacy-policy-acceptance]").is(':checked')) {
            $("input[name=privacy-policy-acceptance]")
                .closest('label')
                .css('color', 'red')
                .addClass('shake');
            setTimeout(function () {
                $(".shake").removeClass('shake');
            }, 500);

            //If is signature-pad if empty
        } else if (signaturePad.isEmpty() == true) {
            $('html, body').animate({
                scrollTop: $('.signature-title').offset().top - $('nav.main-navbar').outerHeight()
            }, 500);
            $('canvas').css('border-color', 'red')
                .addClass('shake');
            setTimeout(function () {
                $(".shake").removeClass('shake');
            }, 500);

            //signature-pad was filled and privacy-policy-acceptance is checked
        } else {
            $('aside.spinner-overlay').css('display', 'flex');
            //fill input with base64-string contained in signature-pad
            $('input[name=signatureBase64]').val(signaturePad.toDataURL("image/svg+xml"));
            let dataForm = JSON.stringify($('form[name="form-customer"]').serializeFormJSON());

            // // birthday check
            $.post(baseUrl + '/customer/store', dataForm)
                .done(function (result) {
                    window.location.href = result.redirectUrl + encodeURI("?message=Cliente creato correttamente") + "&messageClass=bg-success";
                })
                .fail(function (result) {
                    //check if pdf was created
                    if (result.responseJSON.message.includes("pdf")) {
                        window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                            + '&source=customer' + encodeURI("&message=" + result.responseJSON.message) + '&messageClass=bg-danger';
                    }
                    displayErrors(result); //.form-customer

                    // CHECK MAIL WRONG ERROR
                    if (result.responseJSON.message.includes("SQLSTATE[23000]")) {
                        alert("Email inserita associata ad un altro cliente");
                    }
                    // // Others ERRORS and MESSAGES
                    if (result.responseJSON.message.includes("was invalid")) {
                        alert("Dati inseriti non validi, controlla Nome o Email.");
                    }
                    if (result.responseJSON.errors != undefined) {
                        let myerrors = JSON.stringify(result.responseJSON.errors);
                        console.log('CONSOLE-LOG:result-errors: ' + myerrors);
                    }
                    if (result.responseJSON.message != undefined) {
                        console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                        console.log('CONSOLE-LOG:result-message: ' + JSON.stringify(result));
                    }

                    $('html, body').animate({
                        scrollTop: $('form[name="form-customer"]').offset().top - $('nav.main-navbar').outerHeight()
                    }, 500);
                    //END - MAIL CHECK

                    $('aside.spinner-overlay, aside.privacy-popup').hide();
                });
        }
        event.preventDefault();
    });

    $(document).on('click', '.button-update-customer', function (event) {
        let customerId = $(this).data('customerId');

        if ($('input[name=newsletter-acceptance]').hasClass('changed') ||
            $('input[name=privacy-policy-acceptance]').hasClass('changed')) {
            $('form[data-id=' + customerId + ']').addClass('generate-pfd');
            if (signaturePad.isEmpty() == true) {
                $('html, body').animate({
                    scrollTop: $('.signature-title').offset().top - $('nav.main-navbar').outerHeight()
                }, 500);
                $('canvas').css('border-color', 'red')
                    .addClass('shake');

                setTimeout(function () {
                    $(".shake").removeClass('shake');
                }, 500);
                return false;
            }
            if (!$("input[name=privacy-policy-acceptance]").is(':checked')) {
                $('html, body').animate({
                    scrollTop: $('.signature-title').offset().top - $('nav.main-navbar').outerHeight()
                }, 500);
                $('[name=privacy-policy-acceptance]').closest('label').css({"border-color": "red", "color": "red"})
                    .addClass('shake');

                setTimeout(function () {
                    $(".shake").removeClass('shake');
                }, 500);
                return false;
            }
        }
        $('aside.spinner-overlay').css('display', 'flex');

        if ($('form[data-id=' + customerId + ']').hasClass('generate-pfd')) {
            let padString = signaturePad.toDataURL("image/svg+xml");
            $('input[name=signatureBase64]').val(signaturePad.toDataURL("image/svg+xml"));
        }
        let dataForm = JSON.stringify($('form[data-id=' + customerId + ']').serializeFormJSON());

        $.post(baseUrl + '/customer/update/' + customerId, dataForm)
            .done(function (result) {
                if ($('.keyword').length) {
                    $('.keyword').trigger('keyup');
                    setTimeout(function () {
                        scrollFormCustomer(result.customerId);
                    }, 1000);
                } else {
                    window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                        + 'customerId=' + result.customerId + '&source=customer' + encodeURI("&message=Cliente aggiornato correttamente") + '&messageClass=bg-success';
                }
            })
            .fail(function (result) {
                //check if pdf was created
                if (result.responseJSON.message.includes("pdf")) {
                    window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                        + 'customerId=' + customerId + '&source=customer' + encodeURI("&message=" + result.responseJSON.message) + '&messageClass=bg-danger';
                }

                displayErrors(result);
                //START - MAIL CHECK
                if (result.responseJSON.message.includes("SQLSTATE[23000]")) {
                    alert("Email inserita associata ad un altro cliente");
                }
                if (result.responseJSON.message.includes("was invalid")) {
                    alert("Email inserita non valida.");
                }
                $('html, body').animate({
                    scrollTop: $('form[name="form-customer"]').offset().top - $('nav.main-navbar').outerHeight()
                }, 500);
                //END - MAIL CHECK
                $('aside.spinner-overlay, aside.privacy-popup').hide();
            });
        event.preventDefault();
    });

    $(document).on('click', '.button-customer-delete', function () {
        let customerId = $(this).data('customerId');
        let response = confirm('Sei sicuro di voler eliminare il cliente selezionato?');

        if (response) {
            $.get(baseUrl + '/customer/delete/' + customerId, function () {
                if ($('.keyword').length) {
                    $('.keyword').trigger('keyup');
                } else {
                    window.location.href = baseUrl + '/customer/index' + encodeURI("?message=Cliente cancellato correttamente") + '&messageClass=bg-success';
                }
            });
        }
    });

    $(document).on('click', '.button-new-order', function (event) {
        let customerId = $(this).data('id');
        $(this).css('pointer-events', 'none');

        $.get(baseUrl + '/customer/getneworderline/' + customerId).done(function (data) {
            $('#resultBlock-' + customerId + ' .new-order').append(data);
            $(".datepicker").datepicker({
                showOn: "button",
                buttonImage: "/images/calendar.svg",
                buttonImageOnly: true,
                buttonText: "Select date",
                maxDate: '+0m +0w'
            });

            $('html, body').delay(300).animate({
                scrollTop: $('#newOrderDiv-' + customerId + ' .order-row .new').offset().top - $('nav.main-navbar').outerHeight()
            }, 600);

            $('#newOrderDiv-' + customerId + ' .order-row .new input[name=details]').focus();
        });

        event.preventDefault();
    });

    $(document).on('click', '.button-save-new-order', function (event) {
        $(this).closest('form').find('input, select').prop('readonly', true);
        let purchId = $(this).data('purchaseId');

        let dataForm = JSON.stringify($(this).closest('form').serializeFormJSON());

        let source_ =
            purchId !== undefined ? '&source=purchase' + '&purchaseId=' + purchId
                : '&source=newPurchase';

        let inputDate = $('.datepicker.form-control.hasDatepicker').val();
        inputDate = invertDate(inputDate);
        if (inputDate > getCurrentDate()) {
            alert('Per piacere inserisci una data odierna o passata');
        } else {
            $.post(baseUrl + '/purchase/store', dataForm)
                .done(function (result) {
                    if ($('.keyword').length) {
                        $('.keyword').trigger('keyup');
                        setTimeout(function () {
                            scrollFormCustomer(result.customerId);
                        }, 1000);

                    } else {
                        window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                            + 'customerId=' + result.customerId + source_ + encodeURI("&message=Ordine salvato correttamente") + '&messageClass=bg-success';
                    }
                })
                .fail(function (result) {
                    if (result.responseJSON.errors != undefined) {
                        console.log('CONSOLE-LOG:result-errors: ' + result.responseJSON.errors);
                    }
                    if (result.responseJSON.message != undefined) {
                        console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                    }
                    displayErrors(result);
                });
        }
        event.preventDefault();
    });

    $(document).on('click', '.button-purchase-save', function () {
        let purchId = $(this).data('purchaseId');
        let inputDate = $('form[data-purchase-id=' + purchId + ']').find('.datepicker.form-control.hasDatepicker').val();

        inputDate = invertDate(inputDate);
        if (inputDate > getCurrentDate()) {
            alert('Per piacere inserisci una data odierna o passata');
        } else {
            let response = confirm('Sei sicuro di voler salvare l\'ordine selezionato?');

            if (response) {
                let dataForm = JSON.stringify($('form[data-purchase-id=' + purchId + ']').serializeFormJSON());

                $.post(baseUrl + '/purchase/update/' + purchId, dataForm)
                    .done(function (result) {
                        if ($('.keyword').length) {
                            $('.keyword').trigger('keyup');
                            setTimeout(function () {
                                scrollFormCustomer(result.customerId);
                            }, 1000);

                        } else {
                            window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                                + 'customerId=' + result.customerId + '&source=purchase' + '&purchaseId=' + purchId + encodeURI("&message=Ordine aggiornato correttamente") + '&messageClass=bg-success';
                        }
                    })
                    .fail(function (result) {
                        if (result.responseJSON.errors != undefined) {
                            console.log('CONSOLE-LOG:result-errors: ' + result.responseJSON.errors);
                        }
                        if (result.responseJSON.message != undefined) {
                            console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                        }
                        displayErrors(result);
                    });
            }
        }
    });

    $(document).on('click', '.button-purchase-delete', function () {
        let purchId = $(this).data('purchaseId');
        let response = confirm('Sei sicuro di voler eliminare l\'ordine selezionato?');
        let customerId = $(this).closest('.resultBlock').data('id');

        if (response) {
            $.get(baseUrl + '/purchase/delete/' + purchId, function (result) {
                if ($('.keyword').length) {
                    $('.keyword').trigger('keyup');
                    setTimeout(function () {
                        scrollFormCustomer(result.customerId);
                    }, 1000);
                } else {
                    window.location.href = baseUrl + url.pathname + '?' + (page !== null ? 'page=' + page + '&' : '')
                        + 'customerId=' + result.customerId;

                    if ($('#resultBlock-' + customerId).find('.order-row').length != 1) {
                        window.location.href += '&source=newPurchase' + encodeURI("&message=Ordine eliminato correttamente") + '&messageClass=bg-success';
                    }
                }
            });
        }
    });

    $(document).on('click', '.button-update-user', function (event) {
        let userId = $(this).data('userId');
        let dataForm = JSON.stringify($('form[data-id=' + userId + ']').serializeFormJSON());

        $.post(baseUrl + '/user/update/' + userId, dataForm)
            .done(function () {
                window.location.href = baseUrl + '/user/index';
            })
            .fail(function (result) {
                if (result.responseJSON.errors != undefined) {
                    console.log('CONSOLE-LOG:result-errors: ' + result.responseJSON.errors);
                }
                if (result.responseJSON.message != undefined) {
                    console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                }
                displayErrors(result);
            });
        event.preventDefault();
    });

    $(document).on('click', '.button-save-user', function (event) {
        let dataForm = JSON.stringify($(this).closest('form').serializeFormJSON());

        $.post(baseUrl + '/user/store', dataForm)
            .done(function () {
                window.location.href = baseUrl + '/user/index';
            })
            .fail(function (result) {
                if (result.responseJSON.errors != undefined) {
                    console.log('CONSOLE-LOG:result-errors: ' + result.responseJSON.errors);
                }
                if (result.responseJSON.message != undefined) {
                    console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                }
                displayErrors(result);
            });
        event.preventDefault();
    });

    $(document).on('click', '.button-delete-user', function (event) {
        let userId = $(this).data('user-id');
        let response = confirm('Sei sicuro di voler eliminare il collaboratore selezionato?');
        if (response) {
            let dataForm = JSON.stringify($('form[data-id=' + userId + ']').serializeFormJSON());

            $.get(baseUrl + '/user/delete/' + userId, dataForm)
                .done(function () {
                    window.location.href = baseUrl + '/user/index';
                })
                .fail(function (result) {
                    if (result.responseJSON.errors != undefined) {
                        console.log('CONSOLE-LOG:result-errors: ' + result.responseJSON.errors);
                    }
                    if (result.responseJSON.message != undefined) {
                        console.log('CONSOLE-LOG:result-message: ' + result.responseJSON.message);
                    }
                    displayErrors(result);
                });

            event.preventDefault();
        }
    });

    // ABILITA MODIFICHE PER
    $('body.customer-add form, body.user-add form, body.user-index form').removeClass('locked');
    $('body.customer-add a.button-save-customer, body.user-add a.button-save-user').show();
    $('body.customer-add input, body.user-add input, body.customer-add select, body.user-add select, body.user-index input').attr('readonly', false);
    $('body.customer-add .show-privacy-popup').show();

    $(document).on('click', 'form[name=form-purchase]:not(.active)', function () {
        $(this)
            .removeClass('locked')
            .addClass('active')
            .children('.actions').slideDown();
        $(this).find('input, textarea, select').attr('readonly', false);
    });

    $(document).on('click', 'form[name=form-purchase].active .trigger', function () {
        $(this)
            .closest('form')
            .removeClass('active')
            .addClass('locked')
            .children('.actions').slideUp();
        $(this).closest('form').find('input, textarea, select').attr('readonly', true);
    });

    $(document).on('click', 'form[name=form-purchase].active .reset', function () {
        $(this)
            .closest('form')
            .find('.trigger')
            .trigger('click');
    });

    $(document).on('click', '.button-purchase-edit', function (event) {
        let purchId = $(this).data('purchaseId');
        $('form[data-purchase-id=' + purchId + '] input, form[data-purchase-id=' + purchId + '] textarea').prop('readonly', false);
        $(this).hide();
        $('form[data-purchase-id=' + purchId + '] .button-purchase-save').show();
        event.preventDefault();
    });

    $(document).on('click', '.button-edit-customer, .button-edit-user ', function () {
        let saveOrder = $('.button-save-new-order');

        if (!saveOrder.hasClass('editable')) {
            $('.button-update-customer')
                .css('display', 'flex')
                .addClass('editable');
            $('.button-edit-customer').hide();

            $('.readonly').each(function () {
                $(this).css('background', 'white').prop('readonly', false);
            });
        } else {
            saveOrder.css({'border-color': 'darkred', 'color': 'darkred'});
            alert('You need to save the current Order first');
        }
    });

    $(document).on('click', '.customer-search a.edit, .customer-index a.edit', function (event) {
        window.location.href = baseUrl + '/customer/edit/' + $(this).data('id');
        event.preventDefault();
    });

    $(document).on('click', '.customer-edit a.edit', function (event) {
        event.preventDefault();
        var fields = {};
        $('form[data-id=' + $(this).data('id') + ']').find(":input").each(function () {
            // The selector will match buttons; if you want to filter
            // them out, check `this.tagName` and `this.type`; see
            // below
            fields[this.name] = $(this).val();
        });

        $(this).closest('form').removeClass('locked');
        $('form[data-id=' + $(this).data('id') + '] input, form[data-id=' + $(this).data('id') + '] select').attr('readonly', false);
        $(this).hide();
        $('form[data-id=' + $(this).data('id') + '] .button-cancel-edit-customer').show();
        $('form[data-id=' + $(this).data('id') + '] .button-update-customer').show();
        $('form[data-id=' + $(this).data('id') + '] .button-customer-delete').show();
        $('form[data-id=' + $(this).data('id') + '] .button-cancel-edit-user').show();
        $('form[data-id=' + $(this).data('id') + '] .button-update-user').show();
        let currentEl = $(this);
        $('html, body').delay(300).animate({
            scrollTop: currentEl.closest('.resultBlock').offset().top - $('nav.main-navbar').outerHeight()
        }, 500);
        $('.show-privacy-popup').show();
    });

    // Remember that the checkbox click is fake
    $(document).on('click', 'input[name=newsletter-acceptance]', function () {
        $('input[name=newsletter-acceptance]').toggleClass('changed');
    });

    $(document).on('click', 'input[name=privacy-policy-acceptance]', function () {
        if ($('input[name=privacy-policy-acceptance]').closest('label').hasClass('accepted')) {
            return;
        } else {
            $('input[name=privacy-policy-acceptance]').toggleClass('changed');
        }
    });

    $(document).on('click', '.button-cancel-edit-customer, .button-cancel-edit-user', function (event) {
        $(this).closest('form').addClass('locked');
        $(this).closest('form').data('id');
        $('form[data-id=' + $(this).closest('form').data('id') + '] input, form[data-id=' + $(this).closest('form').data('id') + '] select').attr('readonly', true);
        $(this).hide();
        $('.button-update-customer, .button-update-user, .button-customer-delete, .button-customer-user').hide();
        $('a.edit').show();
        $('.show-privacy-popup').hide();
    });

    // if customer is saved do not trigger edit mode
    if (!window.location.href.includes('customerId')) {
        $('body.customer-edit a.edit').trigger('click');
    }

    $(document).on('click', '.privacy-popup-scroller', function (event) {
        $('div.privacy-popup').delay(300).animate({
            scrollTop: $('.table.table-bordered').offset().top - 50
        }, 500);
        $('button.clear').trigger('click');
    });
});

